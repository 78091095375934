export const BLOCKS_FRAGMENT = `
	blocks {
		name
		saveContent
		originalContent		
        ... on AcfCalloutCardRowBlock {
			dynamicContent
        	name
			originalContent
			CalloutCardRow {
				cards {
					title
					titleLink {
						url
						target
						title
					}
					text
					icon {
						sourceUrl
						altText
					}
				}
			}
    	}
        ... on AcfTwoColImageTextBlock {
        	dynamicContent
        	name
          	originalContent
          	TwoColImageText {
            	clickThroughLink
            	clickThroughText
            	content
            	image {
              		mediaDetails {
                		height
                		width
              		}
              		sourceUrl
            	}
            	title
            	redBarTop
            	imageRight
      		}
	        attributes {
          		sidebarTitle
          		anchor
        	}
    	}
    	... on AcfTestimonialBlock {
        	dynamicContent
        	originalContent
        	name
        	Testimonial {
	          	clickThroughLink {
					title
					url
				}
	          	quote
				quoteSource
				quoteDescription
				fullWidth
	          	image {
	            	sourceUrl
	            	mediaDetails {
		              	height
		              	width
	            	}
	          	}
	    	}
	        attributes {
          		sidebarTitle
          		anchor
        	}
      	}
      	... on AcfWysiwygBlock {
        	dynamicContent
        	originalContent
        	name
	        wysiwyg {
	          content
        	}
	        attributes {
          		sidebarTitle
          		anchor
        	}
      	}
      	... on AcfIconTitleTextListBlock {
	        dynamicContent
	        originalContent
        	name
	        IconTitleText {
	         	items {
		            title
		            text
		            icon {
		            	mediaDetails {
			                width
		                	height
		              	}
	              		sourceUrl
		            }
          		}
          	}
	        attributes {
          		sidebarTitle
          		anchor
        	}
        }
      	... on AcfRawHtmlBlock {
	        dynamicContent
	        originalContent
        	name
	        RawHtml {
	         	content
          	}
	        attributes {
          		sidebarTitle
          		anchor
        	}
        }
		... on AcfPageMenuBlock {
	        dynamicContent
        	name
	        originalContent
	        PageMenu {
				menuItems {
					link {
						title
						url
					}
					icon {
						mediaDetails {
						  height
						  width
						}
						sourceUrl
				  	}
				}
				showCallbackButton
				requestCallbackButton {
					buttonText
					modalFormId
					modalText
					modalTitle
					title
					content
				}
	        }
			attributes {
				sidebarTitle
				anchor
		  	}
      	}
      	... on AcfNewsletterBlock {
	        dynamicContent
        	name
	        originalContent
	        Newsletter {
	        	content
	        	title
	        }
	        attributes {
						sidebarTitle
						anchor
        	}
	    }
      	... on AcfAbixNewsletterBlock {
	        dynamicContent
        	name
	        originalContent
	        AbixNewsletter {
	        	title
				buttonText
	        }
	        attributes {
						sidebarTitle
						anchor
        	}
	    }
	    ... on AcfPromoBannerBlock {
	        dynamicContent
        	name
	        originalContent
	        PromoBanner {
	          backgroundImage {
	            mediaDetails {
	              height
	              width
	            }
	            sourceUrl
	          }
	          backgroundImageMobile {
	            mediaDetails {
	              height
	              width
	            }
	            sourceUrl
	          }
	          buttonLink
	          title
	          fieldGroupName
	          buttonType
	          buttonText
	        }
	        attributes {
						sidebarTitle
						anchor
        	}
	    }
	    ... on AcfHeroBannerBlock {
	        dynamicContent
        	name
	        originalContent
	        HeroBanner {
				autoSlide
				autoSlideDuration
				slides {
					heading
					text
					buttonSolid {
						title
						target
						url
					  }
					buttonTransparent {
						title
						target
						url
					  }
					backgroundImage {
						mediaDetails {
						  height
						  width
						}
						sourceUrl
					  }
				}
	        }
	        attributes {
						sidebarTitle
						anchor
        	}
	    }
	    ... on AcfContentHighlightTilesBlock {
	        dynamicContent
        	name
	        originalContent
	        ContentHighlightTiles {
	          viewAllText
	          viewAllLink
	          title
	          tiles {
	            category
	            excerpt
	            fieldGroupName
	            link
	            title
	            image {
	              mediaDetails {
	                height
	                width
	              }
	              sourceUrl
	            }
	          }
	        }
	        attributes {
						sidebarTitle
						anchor
        	}
	    }
	    ... on AcfFullWidthCalloutBlock {
	    	dynamicContent
	        originalContent
	        name
	        FullWidthCallout {
	          title
	          showIcon
	          fieldGroupName
	          content
	          buttonLink
	          buttonText
	        }
	        attributes {
						sidebarTitle
						anchor
        	}
	    }
      	... on AcfFullWidthTileBlock {
	        dynamicContent
        	name
	        originalContent
	        FullWidthTile {
	        	content
	        	title
	        	link
	        	linkText
				tileImage {
					mediaDetails {
					  height
					  width
					}
					sourceUrl
			  	}
	        }
	        attributes {
						sidebarTitle
						anchor
        	}
	    }
	    ... on AcfBlockQuoteBlock {
	        dynamicContent
        	name
	        originalContent
	        BlockQuote {
	          content
	        }
	        attributes {
						sidebarTitle
						anchor
        	}
      	}
		... on AcfButtonBlock {
		  dynamicContent
          name
		  originalContent
		  Button {
		    link {
		      title
		      target
		      url
		    }
		    fieldGroupName
		  }
		}
      	... on AcfRequestACallbackButtonBlock{
	        dynamicContent
        	name
	        originalContent
	        RequestACallback {
	          title
			  content
			  showIcon
	          modalTitle
	          modalText
	          buttonText
	          modalFormId
			  displayAsFullWidth
			  wysiwygText
			  addTopMargin
	        }
	        attributes {
						sidebarTitle
						anchor
        	}
      	}
		... on AcfAccordionBlock {
	        dynamicContent
        	name
	        originalContent
	        Accordion {
	          title
	          items {
				header
				content
				anchor
			  }
	        }
			attributes {
				sidebarTitle
				anchor
		  	}
      	}
		... on AcfPageTextBlock {
	        dynamicContent
        	name
	        originalContent
	        PageText {
	          introduction
			  content
	        }
			attributes {
				sidebarTitle
				anchor
		  	}
      	}
		... on AcfDownloadsBlock {
			dynamicContent
			name
			originalContent
			Downloads {
				downloads {
					download {
						mediaItemUrl
					}
					buttonText
				}
			}
			attributes {
				sidebarTitle
				anchor
		  	}
		}
		... on AcfTimelineBlock {
	    	dynamicContent
        	name
	        originalContent
	        Timeline {
	        	title
	          	sections {
					image {
						mediaDetails {
							width
							height
						}
						sourceUrl
		        	}
					title
					excerpt
			  	}
			  	footnotes {
					footnote
			  	}
	        }
			attributes {
				sidebarTitle
				anchor
		  	}
      	}
		... on AcfOfficesBlock {
			dynamicContent
			originalContent
			Offices {
				offices {
					officesGroup {
						officesGroupTitle
						officesGroupSidebarTitle
						fieldGroupName
						offices {
							officeTitle
							officeInformation
						}
					}
				}
			}
			name
			attributes {
				sidebarTitle
				anchor
			}
		}
		... on AcfGravityFormBlock {
			dynamicContent
			originalContent
			GravityForm {
				gravityFormId
			}
			name
			attributes {
				sidebarTitle
				anchor
			}
		}
        ... on AcfSlimLineImageTextBlock {
           	dynamicContent
          	originalContent
	        name
	        attributes {
	        	id
	            className
				anchor
	        }
          	SlimLineImageText {
	            clickThroughLink
	            clickThroughText
				clickThroughIsButton
				clickThroughOpenNewTab
				clickThroughPositionTop
	            content
	            imageRight
	            title
	            image {
              		mediaItemUrl
              		sourceUrl
              		mediaDetails {
                		height
                		width
              		}
            	}
          	}
        }
		... on AcfMorganPollChartsBlock {
	        dynamicContent
	        originalContent
	        name
	        MorganPollCharts {
	        	col1GraphText1
          		col1GraphText2
				col1GraphTitle1
				col1GraphTitle1Color
				col1GraphTitle2Color
				col1GraphTitle2
				col1GraphValue1
				col1GraphValue2
				col1SubTitle
				col1Title
				col2Title
				col2SubTitle
				col2GraphValue4
				col2GraphValue3
				col2GraphValue2
				col2GraphValue1
				col2GraphTitle4Color
				col2GraphTitle4
				col2GraphTitle3Color
				col2GraphTitle3
				col2GraphTitle2Color
				col2GraphTitle2
				col2GraphTitle1Color
				col2GraphTitle1
				col3GraphBottomLineChartValues {
					chartValue1
					chartValue2
					chartValue3
					chartValue4
					chartValue5
					chartValue6
					chartValue7
				}
				col3GraphBottomSubTitle
				col3GraphBottomTitle
				col3GraphBottomValue1
				col3GraphBottomValue2
				col3GraphBottomValue2Color
				col3GraphTopLineChartValues {
					chartValue1
					chartValue2
					chartValue3
					chartValue4
					chartValue5
					chartValue6
					chartValue7
				}
				col3GraphTopSubTitle
				col3GraphTopTitle
				col3GraphTopValue1
				col3GraphTopValue2
				col3GraphTopValue2Color
				col4GraphBottomLineChartValues {
					chartValue1
					chartValue2
					chartValue3
					chartValue4
					chartValue5
					chartValue6
					chartValue7
				}
				col4GraphBottomValue2Color
				col4GraphBottomValue2
				col4GraphBottomValue1
				col4GraphBottomTitle
				col4GraphBottomSubTitle
				col4GraphTopLineChartValues {
					chartValue1
					chartValue2
					chartValue3
					chartValue4
					chartValue5
					chartValue6
					chartValue7
				}
				col4GraphTopSubTitle
				col4GraphTopTitle
				col4GraphTopValue1
				col4GraphTopValue2
				col4GraphTopValue2Color
				title
        	}
			attributes {
				sidebarTitle
				anchor
			}
      	}
	}
`;
